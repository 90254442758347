import React from 'react'
import PropTypes from 'prop-types'
import { Link } from 'gatsby'
import face from '../assets/images/covid.png'
import hand from '../assets/images/hand-sanitizer.png'

const Header = (props) => (
    <div>
	    <header id="header" className="alt">
	        <Link to="/" className="logo" title="NamasteGod Logo"></Link>
	        <span>
	        	<img src={face} alt="NamasteGod Mask"/>Stay Safe. <Link to="/puja-through-video-conference">Book Puja Online</Link><img src={hand} alt="NamasteGod Hand Sanitizer"/>
	        </span>
	        <nav>
	            <a className="menu-link" onClick={props.onToggleMenu} href="#" title="NamasteGod Menu"></a>
	        </nav>
	    </header>
	    <div className="header-banner">
	    	<p>Perform puja via video conferencing. <Link to="/puja-through-video-conference">Know More</Link></p>
	    </div>
    </div>
)

Header.propTypes = {
    onToggleMenu: PropTypes.func
}

export default Header
