import React, { useState } from 'react'
import PropTypes from 'prop-types'
import { Link } from 'gatsby'

const Menu = (props) => {
    const [showPanel, togglePanel] = useState(false);
  return ( 
    <nav id="menu">
        <div className="inner">
            <ul className="links">
                <li><Link onClick={props.onToggleMenu} to="/findonline/hindu/online-pandit-ji-booking-services-for-pooja-or-puja-in-bangalore-india">Pujas</Link></li>
                <li><a onClick={() => togglePanel(!showPanel)}>Pandits</a></li>
                {showPanel && (
                    <ul className="links">
                        <li><Link onClick={props.onToggleMenu} to="/findonline/hindu/north-indian-hindi-speaking-pandit-ji-for-puja-in-bangalore-india-for-marriage-wedding-vedic-hindu-kumaoni-kashmiri-priests">North Indian Pandits</Link></li>
                        <li><Link onClick={props.onToggleMenu} to="/findonline/hindu/bihari-pandit-in-bangalore-india-for-puja-marriage-wedding-mithila-purohits-for-vivah">Bihari Pandits</Link></li>
                        <li><Link onClick={props.onToggleMenu} to="/findonline/hindu/tamil-vadhyars-in-bangalore-india-vedic-tamil-priests">Tamil Vadhyars</Link></li>
                        <li><Link onClick={props.onToggleMenu} to="/findonline/hindu/telugu-brahmin-purohits-or-andhra-pandits-in-bangalore-india-vedic-pujari-experienced-priests">Telugu Purohits</Link></li>
                        <li><Link onClick={props.onToggleMenu} to="/findonline/hindu/malayalee-pandit-in-bangalore-best-malayali-purohit-in-bangalore-india-vedic-priest-and-pujari">Malayali Pandits</Link></li>
                        <li><Link onClick={props.onToggleMenu} to="/findonline/hindu/kannada-purohits-in-bangalore-best-kannada-brahmin-pandit-and-pujari">Kannada Purohits</Link></li>           
                    </ul>
                )}
            </ul>
        </div>
        <a className="close" onClick={props.onToggleMenu} href="javascript:;">Close</a>
    </nav>
    )
}

Menu.propTypes = {
    onToggleMenu: PropTypes.func
}

export default Menu
