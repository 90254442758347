import React from 'react'
import PropTypes from 'prop-types'
import { Link } from 'gatsby'

const Footer = (props) => (
    <footer id="footer">
        <div className="inner">
            <section>
                <ul className="links">
                    <li><Link onClick={props.onToggleMenu} to="/blog">Blog</Link></li>
                    <li><Link onClick={props.onToggleMenu} to="/about-us">About Us</Link></li>
                    <li><Link onClick={props.onToggleMenu} to="/terms-of-service">Terms of Service</Link></li>
                    <li><Link onClick={props.onToggleMenu} to="/privacy-policy">Privacy Policy</Link></li>
                </ul>
            </section>
            <section>
                <ul className="icons">
                    <li><a href="https://twitter.com/namastegodindia" target="_blank" rel="noreferrer" className="icon alt fa-twitter" title="NamasteGod Twitter"><span className="label">Twitter</span></a></li>
                    <li><a href="https://www.facebook.com/namastegodindia" target="_blank" rel="noreferrer" className="icon alt fa-facebook" title="NamasteGod Facebook"><span className="label">Facebook</span></a></li>
                    <li><a href="https://www.instagram.com/namastegod.india" target="_blank" rel="noreferrer" className="icon alt fa-instagram" title="NamasteGod Instagram"><span className="label">Instagram</span></a></li>
                    <li><a href="https://www.youtube.com/channel/UCSRchwHYLnyojdzp1BvYW6w" target="_blank" rel="noreferrer" className="icon alt fa-youtube-play" title="NamasteGod Youtube"><span className="label">Youtube</span></a></li>
                    <li><a href="https://in.linkedin.com/company/namaste-god" target="_blank" rel="noreferrer" className="icon alt fa-linkedin" title="NamasteGod LinkedIn"><span className="label">LinkedIn</span></a></li>
                </ul>
                <ul className="copyright">
                    <li>&copy; 2021 NamasteGod.com</li>
                </ul>
            </section>
        </div>
    </footer>
)

Footer.propTypes = {
    onToggleMenu: PropTypes.func
}

export default Footer
