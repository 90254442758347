import React from 'react'

const Contact = (props) => (
    <section id="contact">
        <div className="inner">
            <section>
                <form name="Contact" method="POST" netlify-honeypot="bot-field" data-netlify="true" action="/success">
                    <input type="hidden" name="bot-field" />
                    <input type="hidden" name="form-name" value="Contact" />
                    <div className="field half first">
                        <label htmlFor="name">Phone</label>
                        <input type="text" name="name" id="name" required="true"/>
                    </div>
                    <div className="field half">
                        <label htmlFor="email">Email</label>
                        <input type="email" name="email" id="email" />
                    </div>
                    <div className="field">
                        <label htmlFor="message">Message</label>
                        <textarea name="message" id="message" rows="6"></textarea>
                    </div>
                    <ul className="actions">
                        <li><input type="submit" value="Send Message" className="special" /></li>
                        <li><input type="reset" value="Clear" /></li>
                    </ul>
                </form>
            </section>
            <section className="split">
                <section>
                    <div className="contact-method">
                        <span className="icon alt fa-envelope"></span>
                        <h3>Email</h3>
                        <a href="mailto:info@namastegod.com">info@namastegod.com</a>
                    </div>
                </section>
                <section>
                    <div className="contact-method">
                        <span className="icon alt fa-phone"></span>
                        <h3>Contact Us</h3>
                        <span>Please fill out the contact us form, our team will get in touch</span>
                    </div>
                </section>
                <section>
                    <div className="contact-method">
                        <span className="icon alt fa-home"></span>
                        <h3>Address</h3>
                        <span>#142/1, 17th Cross, 18th Main<br />
                        JP Nagar 5th Phase, Bengaluru<br />
                        Karnataka 560078</span>
                    </div>
                </section>
            </section>
        </div>
    </section>
)

export default Contact
