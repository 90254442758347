import React from 'react'
import PropTypes from 'prop-types'

import '../assets/scss/main.scss'
import Header from './Header'
import Menu from './Menu'
import Contact from './Contact'
import Footer from './Footer'
import pic1 from '../assets/images/whatsapp-logo.png'

class Layout extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            isMenuVisible: false,
            loading: 'is-loading'
        }
        this.handleToggleMenu = this.handleToggleMenu.bind(this)
    }

    componentDidMount () {
        this.timeoutId = setTimeout(() => {
            this.setState({loading: ''});
        }, 100);
    }

    componentWillUnmount () {
        if (this.timeoutId) {
            clearTimeout(this.timeoutId);
        }
    }

    handleToggleMenu() {
        this.setState({
            isMenuVisible: !this.state.isMenuVisible
        })
    }

    render() {
        const { children } = this.props

        return (
            <div className={`body ${this.state.loading} ${this.state.isMenuVisible ? 'is-menu-visible' : ''}`}>
                <div id="wrapper">
                    <Header onToggleMenu={this.handleToggleMenu} />
                    {children}
                    <div className="help">
                        <a href="https://api.whatsapp.com/send?phone=918197249957&text=Hey, I need some help." target="_blank" rel="noreferrer" className="whatsapp-icon">
                            <img src={pic1} alt="WhatsApp Icon"/>
                        </a>
                    </div>
                    <div className="phone">
                        <a href="tel:8197249957" title="NamasteGod Call">
                            <i className="fa fa-phone fa-2x circle-icon" aria-hidden="true" title="NamasteGod Call" aria-label="NamasteGod Call"></i>
                        </a>
                    </div>
                    <Contact />
                    <Footer />
                </div>
                <Menu onToggleMenu={this.handleToggleMenu} />
            </div>
        )
    }
}

export default Layout
